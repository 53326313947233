<template>
  <div class="row">
    <div class="col-md-6" v-if="reviews.usefulGood">
      <h4>Nejužitečnější pozitivní recenze</h4>
      <ProductReview :review="reviews.usefulGood"/>
    </div>
    <!--
    <div class="col-md-6 recipes-sidebar-smaller-wrapper">
      <RecipesSidebar :smaller="true" />
    </div>
    -->

    <div class="col-12 rating-overview">
      <div class="row">
        <div class="col-12">
          <h4>Respondentů by to doporučilo příteli</h4>
        </div>
        <div class="col-lg-6">
          <strong class="percentage">{{ reviews.rating.recommended }}%</strong>
        </div>
        <div class="col-lg-6">
          <div class="rating-distribution" v-for="rating of reviews.rating.distribution" :key="rating.rating">
            <strong class="rating">{{ rating.rating }} hvězdiček</strong>
            <progress max="100" :value="Math.round(rating.count / reviews.rating.total * 100)"/>
            <span class="respondents">{{ rating.count }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <h4>Všechny recenze</h4>
      <ProductReview v-for="review of reviews.reviews" :review="review" :key="review.id"/>
      <Button color="black" v-if="nextReviewsOffset" @click.native="loadMore" :loading="loading">
        Načíst další
      </Button>
    </div>
  </div>
</template>

<script>
import ProductReview from "@/views/pages/Product/ProductReview";
import Button from "@/views/components/Button";
import {EventBus} from "@/event-bus";
//import RecipesSidebar from "./RecipesSidebar";

export default {
  name: "ProductReviews",
  components: {/*RecipesSidebar,*/ Button, ProductReview},
  props: [
    "reviews", "product"
  ],
  data() {
    return {
      loading: false
    };
  },
  computed: {
    nextReviewsOffset() {
      let loaded = this.reviews.reviews.length;

      if (loaded >= this.reviews.total)
        return null;

      return loaded;
    }
  },
  methods: {
    loadMore() {
      if (this.loading)
        return;

      this.loading = true
      this.$api.loadMoreReviews(this.product, this.nextReviewsOffset)
          .catch(() => {
            EventBus.$emit("flash", "Při načítání recenzí došlo k chybě", "error")
          })
          .finally(() => {
            this.loading = false;
          });
    }
  }
}
</script>

<style scoped>

</style>
<template>
	<span class="helper-tooltip" :class="'color-' + color">
		?
		<span class="content">
			<slot/>
		</span>
	</span>
</template>

<script>
	export default {
		name: "HelperTooltip",
		props: {
			color: {
				default: "black"
			}
		}
	}
</script>
<template>
	<div class="error-page">
		<img src="../../../assets/icons/logo.svg" alt="Logo Koju.cz">
		<h1>Zrovna pro Vás web vylepšujeme</h1>
		<p>Omlouváme se, ale web je dočasně nedostupný Zkuste to prosím později.</p>
	</div>
</template>

<script>
	export default {
		name: "Error503"
	}
</script>
<template>
	<i class="icon" :class="'icon-' + src">
		<component :is="iconComponent"/>
	</i>
</template>

<script>
	export default {
		name: "Icon",
		props: {
			src: String,
			path: {
				type: String,
				default: "icons"
			}
		},
		data() {
			return {
				iconComponent: null
			}
		},
		computed: {
			componentImport() {
				return () => import(`../../assets/${this.path}/${this.src}.svg?inline`);
			},
		},
		created() {
			this.componentImport().then(() => {
				this.iconComponent = () => this.componentImport();
			});
		},
	}
</script>

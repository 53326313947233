<template>
	<div id="app" :class="'theme-' + theme + (loading ? ' is-loading' : '')">
		<Error503 v-if="errorStatus === 503"/>
		<Error500 v-else-if="errorStatus"/>
		<template v-else>
			<div ref="topOfPage"></div>	<!-- empty element used to detect when user has scrolled to the very top of page -->
			<Header v-if="apiDataLoaded"/>
			<!-- Render router view after all necessary API data have been loaded -->
			<router-view class="content-wrapper" v-if="apiDataLoaded"/>
			<Footer/>
      <MiniCart />
			<DeliveryModal :default-visible="false" />
      <PromotedProductModal product-slug="jahoda" v-if="apiDataLoaded" />
			<FlashMessages :messages="flashMessages" @close="closeFlash"/>
			<span class="page-loader" v-if="loading">
				<Loader/>
			</span>
		</template>
	</div>
</template>

<script>
	import Header from "./components/Header";
	import Footer from "./components/Footer";
	import {EventBus} from "../event-bus";
	import Loader from "./Loader";
	import Error500 from "./pages/errors/Error500";
	import Error503 from "./pages/errors/Error503";
	import FlashMessages from "./components/FlashMessages";
	import DeliveryModal from "./components/DeliveryModal";
  import PromotedProductModal from "@/views/components/PromotedProductModal";
  import MiniCart from '@/views/components/Header/MiniCart.vue';

	export default {
		name: "Root",
		metaInfo: {
			titleTemplate: title => {
				return title ? title + " | Koju.cz" : "Ginger shoty & sirupy | Koju.cz";
			},
			htmlAttrs: {
				lang: "cs",
			},
		},
		components: {
      MiniCart,
      PromotedProductModal,
			DeliveryModal,
			FlashMessages,
			Loader,
			Header,
			Footer,
			Error500,
			Error503
		},
		data() {
			return {
				theme: "white",
				loading: 0,
				apiDataLoaded: false,
				errorStatus: null,
				flashMessages: {},
        windowScrollStatus: 0 // 0 = enabled, > 0 = disabled
			};
		},
		watch: {
			$route(to) {
				// Page that isn't homepage section accessed -> scroll to top
				if (!to.meta.home) {
					EventBus.$emit("scrollingToTop");
          return window.scrollTo(0, 0);
				}
			}
		},
		created() {
			this.startLoading();
			this.$api.init().catch(data => {
				this.errorStatus = data ? data.status : 500;
			}).finally(() => {
				this.apiDataLoaded = true;
				this.stopLoading();
			});

			// Full page loader
			EventBus.$on("loading", value => {
				if (value)
					this.startLoading();
				else
					this.stopLoading();
			});

			// Changing page color theme
			EventBus.$on("changeTheme", theme => {
				// No change
				if (this.theme === theme)
					return;

				// Show loading for a while before changing theme for better experience
				this.startLoading();
				setTimeout(() => {
					this.stopLoading();
					this.theme = theme;
          this.$storage.theme = theme;
				}, 200);
			});

			// Toggle <body> overflow to disable scroll
			EventBus.$on("toggleBodyScroll", enabled => {
				this.toggleBodyScroll(enabled);
			});

			// Showing flash messages
			EventBus.$on("flash", (text, type = "success", duration = null) => {
				let keys = Object.keys(this.flashMessages);

				if (!duration)
					duration = type === "error" ? 5 : 3;

				// Increment last key
				let newKey;
				if (keys.length)
					newKey = parseInt(keys[keys.length - 1]) + 1;
				else
					newKey = 0;

				this.$set(this.flashMessages, newKey, {
					text, type,
					timeout: setTimeout(() => {
						this.closeFlash(newKey);
					}, duration * 1000)
				});
			});
		},
		mounted() {
			// Start of Tawk.to Script
      /*
      if (process.env.VUE_APP_ENV !== "local") {
        window.Tawk_API = window.Tawk_API || {};
        window.Tawk_LoadStart = new Date();
        (function () {
          var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
          s1.async = true;
          s1.src = 'https://embed.tawk.to/5fcb61aca1d54c18d8f0aefb/default';
          s1.charset = 'UTF-8';
          s1.setAttribute('crossorigin', '*');
          s0.parentNode.insertBefore(s1, s0);
        })();
      }
      */
			// End of Tawk.to Script
		},
		methods: {
			startLoading() {
				this.loading += 1;

				if (this.loading === 1) {
          this.toggleBodyScroll(false);

          // Hide mini-cart when loader is displayed
          EventBus.$emit("miniCartHide");
        }
			},
			stopLoading() {
				this.loading -= 1;

				if (this.loading < 0)
					this.loading = 0;

				if (this.loading === 0)
				  this.toggleBodyScroll(true);
			},
      toggleBodyScroll(enabled) {
			  this.windowScrollStatus += enabled ? -1 : 1;

        if (this.windowScrollStatus < 0)
          this.windowScrollStatus = 0;

        document.body.style.overflow = this.windowScrollStatus <= 0 ? "" : "hidden";
      },
			closeFlash(key) {
				clearTimeout(this.flashMessages[key].timeout);
				this.$delete(this.flashMessages, key);
			},
		}
	};
</script>

<template>
  <div class="mini-cart" :class="{ empty: !($api.cart && $api.cart.count), visible: displayMiniCart }"
       v-if="$route.name !== 'Cart'">
    <div class="bg-theme-light font-color">
      <div v-if="$api.cart && $api.cart.count" class="mini-cart--table-wrapper">
        <div class="mini-cart--table">
          <div class="mini-cart--product" v-for="(product, id) in $api.cart.content" :key="id">
            <div class="mini-cart--product-img" v-if="product.options.image">
              <img :src="product.options.image" alt="Produkt" loading="lazy">
            </div>
            <div class="mini-cart--product-details">
              <div class="mini-product--description font-accent">
                {{ product.name }}
              </div>
              <div class="mini-product--quantity">
                {{ product.qty }}
                {{ product.options.unit === 'pack' && product.qty != 1 ? 'packs' : product.options.unit }}
              </div>
              <div class="mini-product--sum">
                {{ $price(product.subtotal) }}
              </div>
            </div>
          </div>
        </div>
        <div class="mini-cart--footer">
          <div>
            Celková cena: <strong class="font-accent">{{ $price($api.cart.subtotal) }}</strong>
          </div>

          <router-link :to="{ name: 'Cart' }">
            <Button :color="contrastButtonColor($storage.theme)">
              Přejít do košíku
            </Button>
          </router-link>
        </div>
      </div>
      <div v-else class="mini-cart--empty">
        V košíku zatím nic nemáte.
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../../event-bus';
import Button from '@/views/components/Button.vue';
import { contrastButtonColor } from '@/views/pages/Product.vue';

const miniCartVisible = 5;  // seconds

const miniCartVisibleHoverDelay = 2;  // seconds

export default {
  name: 'MiniCart',
  components: { Button },
  data() {
    return {
      displayMiniCart: false,
      miniCartTimeout: null,
    };
  },
  methods: {
    contrastButtonColor,
  },
  created() {
    EventBus.$on('cartModified', () => {
      clearTimeout(this.miniCartTimeout);
      this.displayMiniCart = true;
      this.miniCartTimeout = setTimeout(
          () => this.displayMiniCart = false,
          miniCartVisible * 1000,
      );
    });

    EventBus.$on('miniCartHover', () => {
      clearTimeout(this.miniCartTimeout);
      this.displayMiniCart = true;
      this.miniCartTimeout = setTimeout(
          () => this.displayMiniCart = false,
          miniCartVisibleHoverDelay * 1000,
      );
    });

    EventBus.$on('miniCartHide', () => {
      clearTimeout(this.miniCartTimeout);
      this.displayMiniCart = false;
    });
  },
};
</script>
// Local storage wrapper with expiration settings
export default {
	get(key) {
		if (!localStorage)
			return null;

		const itemStr = localStorage.getItem(key);

		if (itemStr) {
			const item = JSON.parse(itemStr);
			const now = new Date();

			// Item not expired yet
			if (now.getTime() <= item.expiration) {
				delete item.expiration;
				return item;
			} else
				localStorage.removeItem(key);
		}

		return null;
	},

	set(key, value, expiration) {
		if (!localStorage) return;

		if (typeof value !== "object")
			throw new Error("LocalStorage value must be an object.");

		// Merge expiration object to get defaults
		expiration = {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
			...expiration,
		};

		const expires = new Date();
		expires.setTime(expires.getTime() +
			((((((expiration.days * 24) + expiration.hours)	// get number of hours
				* 60) + expiration.minutes) 				// get number of minutes
				* 60) + expiration.seconds) 				// get number of seconds
			* 1000
		);

		localStorage.setItem(key, JSON.stringify({
			...value,
			expiration: expires.getTime()
		}));
	}
}
<template>
	<div class="container-fluid header" :class="{ open, 'top-of-page': isTopOfPage, 'disable-transition': disableTransition, 'has-alert-bar': hasAlertBar }">
    <AlertBar v-if="hasAlertBar" @hide="hideAlertBar" />
		<vue-position-sticky sticky-class="sticky-wrapper">
			<Navbar @toggle-navbar="open = !open"/>
		</vue-position-sticky>
	</div>
</template>

<script>
	import Navbar from "./Header/Navbar";
	import {EventBus} from "../../event-bus";
  import AlertBar from "./Header/AlertBar.vue";

	export default {
		components: {
      AlertBar,
			Navbar
		},
		data() {
			return {
				open: false,
				intersectionObserverSupported: 'IntersectionObserver' in window,
				scrollObserver: null,
				isTopOfPage: true,
				disableTransition: false,
        hasAlertBar: true,
			};
		},
		watch: {
			$route() {
				// Hide mobile navbar on link click
				this.open = false;
			},
			open(isOpen) {
				this.disableTransition = false;
				EventBus.$emit("toggleBodyScroll", !isOpen);
			},
		},
    methods: {
      hideAlertBar() {
        this.hasAlertBar = false;

        // Temporarily disable transitions to prevent content overfllow when hiding shipping bar
        const originalDisableTransitionValue = this.disableTransition;
        this.disableTransition = true;
        setTimeout(() => {
          this.disableTransition = originalDisableTransitionValue;
        }, 50);
      },
    },
		name: "Header",
		mounted() {
			if (!this.intersectionObserverSupported)
				return;

			// Destroy old scroll observer
			if (this.scrollObserver)
				this.scrollObserver.disconnect();

			// Validate that top of page element is present
			const rootChildren = this.$root.$children;
			if (!rootChildren) return;

			const rootChildRefs = rootChildren[0].$refs;
			if (!rootChildRefs || typeof rootChildRefs.topOfPage !== "object") return;

			this.scrollObserver = new IntersectionObserver(data => {
				this.isTopOfPage = data[0].intersectionRatio > 0;
				this.$nextTick(() => {
					this.disableTransition = false;
				});
			}, {
				threshold: 1.0,
				rootMargin: '15px 0px',
			});

			// Observe when user leaves top of page + threshold
			this.scrollObserver.observe(rootChildRefs.topOfPage);

			// Redirect to top of page just happened -> hide shadow right away without transition
			EventBus.$on("scrollingToTop", () => {
				this.disableTransition = true;
				this.isTopOfPage = true;
			});
		},
	}
</script>

<style lang="sass">

</style>

<template>
  <span class="rating-stars">
    <span class="empty-stars">★★★★★</span>
    <span class="full-stars" :style="'width: ' + (rating * 100 / 5) + '%'">★★★★★</span>
  </span>
</template>

<script>
export default {
  name: "RatingStars",
  props: [
      "rating"
  ]
}
</script>
<template>
	<div v-if="product" class="product-detail" :key="product.id">
		<div class="product-detail-top fold-bg" :class="`fold-bg-${product.color}${product.ingredients !== 'recipes' ? ' smaller-bottom-padding' : ''}`">
			<div class="container">
				<div class="row">
					<div class="col-lg-9 basic-info">
						<div class="top-description">
              <h2 class="suptitle font-brown" v-if="product.is_syrup">Sirup</h2>
              <h2 class="suptitle font-brown" v-else-if="isGingerShot">Ginger shot</h2>
							<h1 class="italic font-brown">{{ product.name }}</h1>
							<div v-html="product.top_desc"></div>
              <!--<h3>Účinky</h3>-->
              <template v-if="product.ingredients !== 'recipes'">
                <EffectsIconsHacker v-if="product.color === 'hacker'" :product="product" class="d-none d-md-flex" />
                <EffectsIcons v-else :product="product" class="d-none d-md-flex" />
              </template>
						</div>
						<div class="preview">
              <template v-if="product.color !== 'hacker' && product.color !== 'pepper' && product.color !== 'bordeaux'">
							  <img :src="product.photos[0]" alt="Náhled produktu" :class="{ pack: product.photos.length === 1 }">
              </template>
              <template v-else>
                <img :src="product.photos[1]" alt="Náhled produktu" class="levitating-bottle-smaller">
                <img :src="product.photos[2]" alt="Náhled produktu" class="levitating-bottle-larger">
                <Icon v-if="product.color === 'hacker'" src="hacker-synapses" path="images" />
                <!--
                <template v-else-if="product.color === 'bordeaux'">
                  <template v-for="x in 20">
                    <img v-for="y in 10" :key="`${y}'-'${x}`" :src="`${apiDomain}/images/product-bordeaux-dust/${y}.png`" class="bordeaux-dust">
                  </template>
                </template>
                -->
                <template v-else-if="product.color === 'pepper'">
                  <img v-for="n in 10" :key="n" src="../../assets/images/product-icons-pepper/pepper-ball.png" class="pepper-ball" alt="Kulička pepře" />
                </template>
              </template>
						</div>
					</div>
          <!--<h3>Účinky</h3>-->
          <div class="col-lg-3" v-if="product.ingredients === 'recipes'">
            <RecipesSidebar @scrollToBuy="scrollToBuy" />
          </div>
          <template v-else>
            <EffectsIconsHacker :product="product" class="d-md-none" v-if="product.color === 'hacker'" />
            <EffectsIcons :product="product" class="d-md-none" v-else />
            <div class="col-lg-3 row ingredients-wrapper">
              <div v-for="ingredient in product.ingredients" :class="`${product.ingredients.length === 1 ? 'col-12 col-sm-6 col-lg-12' : 'col-6 col-md-3 col-lg-6'} ingredient`" :key="ingredient.id">
                <span v-if="product.is_syrup && syrupFruitContent !== 0" class="ingredient-badge">
                  <strong>{{ syrupFruitContent }}%</strong>
                  <span>podíl ovocné složky</span>
                </span>
                <img :src="ingredient.image" :alt="ingredient.name" :class="`ingredient-${ingredient.name.toLowerCase()}`" loading="lazy">
                <strong v-html="ingredient.name" />
              </div>
              <div class="col-12">
                <div class="rating-short" @click="scrollToReviews" v-if="product.reviews">
                  <RatingStars :rating="product.reviews.rating.average" />
                  <span>
                    {{ product.reviews.rating.average.toFixed(1).replace(".", ",") }}*
                    z {{ product.reviews.rating.total }} hodnocení
                  </span>
                  <a @click="scrollToReviews" class="scroll-to-reviews">Zobrazit všechny recenze</a>
                </div>
                <Button :color="buttonColorTop" @click.native="scrollToBuy" class="buy-scroll-button">Varianty produktu</Button>
              </div>
            </div>
          </template>
				</div>
			</div>
		</div>
		<div class="fold-bg-after other-products-wrapper" :class="'fold-bg-after-' + product.color" ref="product-variants">
			<div class="container">
				<h2 class="outline">Varianty produktu</h2>
        <div class="rating-short" @click="scrollToReviews" v-if="product.reviews && product.ingredients === 'recipes'">
          <RatingStars :rating="product.reviews.rating.average" />
          <span>
            {{ product.reviews.rating.average.toFixed(1).replace(".", ",") }}*
            z {{ product.reviews.rating.total }} hodnocení
          </span>
          <a @click="scrollToReviews" class="scroll-to-reviews">Zobrazit všechny recenze</a>
        </div>
				<ProductVariants :product="product" :color="buttonColor" />
        <div class="row" v-if="product.bottom_desc">
          <div v-for="(column, index) in product.bottom_desc.split('&lt;hr&gt;')" :key="index"
               class="bottom-description" :class="bottomDescriptionClasses[index]" v-html="column">
          </div>
          <div v-if="product.color === 'hacker' || product.color === 'pepper'" :class="`bottom-description ${bottomDescriptionClasses[product.bottom_desc.split('&lt;hr&gt;').length]}`">
            <template v-if="product.color === 'hacker'">
              <div class="bottom-description-hacker-effect-wrapper">
                <Icon src="no-thc" path="images/product-icons-hacker" />
                <h5>Produkt <u>neobsahuje</u> THC ani jiné omamné látky</h5>
              </div>
              <div class="product-additional-description">
                <h3>{{ $api.texts.hacker_effects_cbg.name }}</h3>
                <div v-html="$api.texts.hacker_effects_cbg.raw"></div>
              </div>
            </template>
            <div v-else class="product-additional-description">
              <h3>Proč právě kombinace pepře a kurkumy?</h3>
              <div>
                <p>
                  Pepř totiž obsahuje piperin, který výrazně zvyšuje vstřebávání živin obsažených v kurkumě.
                </p>
              </div>
            </div>
          </div>
        </div>
			</div>
		</div>
    <div class="product-reviews" ref="product-reviews" v-if="product.reviews">
      <div class="container">
        <ProductReviews :reviews="product.reviews" :product="product" />
      </div>
    </div>
    <div class="other-products-wrapper white">
      <div class="container">
        <h2 class="outline">Další produkty</h2>
        <OtherProducts :exclude="slug" :syrup="product.is_syrup" :syrup-excude="false" :preferential-sorting="true"/>
      </div>
    </div>
	</div>
</template>

<script>
	import OtherProducts from "../components/OtherProducts";
	import {EventBus} from "../../event-bus";
  import RatingStars from "@/views/pages/Product/RatingStars";
  import ProductReviews from "@/views/pages/Product/ProductReviews";
  import EffectsIcons from "@/views/pages/Product/EffectsIcons";
  import RecipesSidebar from "./Product/RecipesSidebar";
  import ProductVariants from "../components/ProductVariants";
  import Button from "../components/Button";
  import EffectsIconsHacker from "./Product/EffectsIconsHacker";
  import Icon from "../components/Icon";
  import FBPixel from "../../FBPixel";

  export const contrastButtonColor = (bgColor) => {
    if (bgColor === "hacker")
      return "hacker";

    if (bgColor === "pepper")
      return "brown";

    if (bgColor === "red" || bgColor === "brown" || bgColor === "bordeaux")
      return "brown-light";

    if (bgColor === "orange")
      return "orange-light";

    return "black";
  };

	export default {
		name: "Product",
		metaInfo() {
			return {
				title: this.product ? this.product.name : null,
				meta: [{
					name: "description",
					content: this.product ?
						this.product.top_desc.replace(/(<([^>]+)>)/gi, "") :	// Strip HTML tags
						"Detail produktu",
				}]
			};
		},
		components: {
      Icon,
      EffectsIconsHacker,
      Button,
      ProductVariants,
      RecipesSidebar, EffectsIcons, ProductReviews, RatingStars, OtherProducts},
		data() {
			return {
				slug: this.$route.params.slug,
				bottomDescriptionClasses: [
					"col-lg-4",
					"col-md-7 col-lg-4 col-xl-5",
					"col-md-5 col-lg-4 col-xl-3"
				],
				size: null
			};
		},
		watch: {
			$route(newRoute) {
				this.slug = newRoute.params.slug;
			},
			product(newProduct) {
				this.created(newProduct);
			}
		},
		computed: {
      apiDomain() {
        return process.env.VUE_APP_API_DOMAIN;
      },
			product() {
				let products = this.$api.products;

				// Products not loaded yet
				if (!Object.keys(products).length)
					return null;

				let product = products[this.slug];

				// Product not found
				if (!product)
					return this.productNotFound();

				return product;
			},
      buttonColor() {
        return contrastButtonColor(this.product.color);
      },
      buttonColorTop() {
        if (this.product.color === "orange")
          return "orange-dark";

        return this.buttonColor;
      },
      isGingerShot() {
        return !this.product.name.includes("soda");
      },
      syrupFruitContent() {
        if (this.product.name.includes("Jahoda")) {
          return 33;
        }

        if (this.product.name.includes("Mandarinka")) {
          return 55;
        }

        return 0;
      },
		},
		methods: {
			productNotFound() {
				this.$router.replace("/nenalezeno");
			},

			created(product) {
				this.size = "smaller";
				EventBus.$emit("changeTheme", product.color);
        FBPixel.viewProduct(product);
			},

			sizeChanged(size) {
				this.size = size;
			},

      scrollToReviews() {
        this.$smoothScroll(this.$refs["product-reviews"]);
      },

      scrollToBuy() {
        this.$smoothScroll(this.$refs["product-variants"]);
      },
		},
		created() {
			if (this.product)
				this.created(this.product);
		}
	}
</script>
<template>
	<Modal :open="open" @close="open = false" :loading="loading" :close-on-backdrop-click="false" class="delivery-modal">
		<template v-slot:title>
			Kam budeme doručovat?
		</template>
		<template v-slot:body>
			<form @submit.prevent="getResult" class="row">
				<div class="col-12">
					<p>Abychom zjistili, jestli k Vám rozvážíme <strong>chlazenou dopravou</strong>, musíme znát <strong>Vaši adresu</strong>. Popřípadě si můžete produkty vyzvednout na naší dílně v <strong>Prostějově</strong>.</p>
				</div>
				<div class="col-sm-7">
					<div class="form-field">
						<input type="text" v-model="fields.town" placeholder="Město" ref="town">
					</div>
				</div>
				<div class="col-sm-5">
					<div class="form-field">
						<input type="text" v-model="fields.zip" placeholder="PSČ" ref="zipcode" maxlength="6">
					</div>
				</div>
				<div class="col-12 button-wrapper">
					<Button color="black" type="submit">
						Zkontrolovat
					</Button>
				</div>
				<div class="col-12 result-message" :class="'font-' + messageType" v-if="message">
					{{ message }}
				</div>
				<div class="col-12 button-wrapper">
					<Button color="white" type="button" @click.native="open = false" v-if="!messageRequestSuccessful">
						Vyplním později
					</Button>
					<Button color="white" type="button" @click.native="open = false" v-else>
						Pokračovat
					</Button>
				</div>
			</form>
		</template>
	</Modal>
</template>

<script>
	import Modal from "./Modal";
	import Button from "./Button";
	import {EventBus} from "../../event-bus";

	export default {
		name: "DeliveryModal",
		components: {Button, Modal},
    props: {
		  defaultVisible: Boolean
    },
		data() {
			return {
				open: false,
				loading: false,
				fields: {
					town: null,
					zip: null
				},
				message: "",
				messageType: "success",
				messageRequestSuccessful: false,
			};
		},
		created() {
			// Show modal if there is no validated ZIP
			this.open = this.defaultVisible && this.$storage.gibonValidZip === null;
		},
		mounted() {
			this.$refs.town.focus();

			EventBus.$on("toggleDeliveryModal", (open = true) => {
				this.open = open;
			});
		},
		watch: {
			open(value) {
				EventBus.$emit("deliveryModalChanged", value);
			}
		},
		methods: {
			getResult() {
				this.loading = true;

				this.$api.checkDelivery(this.fields.zip, this.fields.town)
					.then(data => {
						this.message = "Dobrá zpráva! " + data.message;
						this.messageType = "success";
						this.messageRequestSuccessful = true;
						this.loading = false;
					})
					.catch(data => {
						// Spin loading for a little while
						setTimeout(() => {
							this.message = data.message;
							this.messageType = "error";
							this.messageRequestSuccessful = data.requestSuccessful;
							this.loading = false;
						}, 200);
					});
			}
		}
	}
</script>

<style scoped>

</style>
<template>
  <div class="row effects-wrapper">
    <div :class="`col-6 effect ${!product.is_syrup && !smaller ? 'col-md-4 ' + offsetClass(index) : 'col-md-3'}`" v-for="(title, icon, index) of items" :key="icon">
      <Icon :src="icon" :path="path" :class="'svg-fill-' + svgFill"/>
      <h4 v-html="title"></h4>
    </div>
  </div>
</template>

<script>
import Icon from "@/views/components/Icon";

export default {
  name: "EffectsIcons",
  props: [
    "product",
    "smaller",
  ],
  components: {
    Icon
  },
  data() {
    return {
      svgFill: "white",
      path: ["pepper", "orange"].includes(this.product.color) ? "images/product-icons-" + this.product.color : "images/product-icons",
      items: Object.fromEntries(Object.entries((() => {
        if (this.product.is_syrup) {
          let ingredient = (() => {
            switch (this.product.color) {
              case 'red': return 'Z&nbsp;čerstvých jahod';
              case 'orange': return 'Z&nbsp;čerstvých mandarinek';
              default: return 'Z&nbsp;čerstvého zázvoru';
            }
          })();

          return {
            'droplet': this.smaller ? 'Z&nbsp;čerstvých surovin' : ingredient,
            'chemistry': 'Bez chemie',
            'portion': '20-34 dávek',
            'czech': 'Český produkt',
          };
        }

        if (this.product.color === 'pepper') {
          return {
            'immunity': 'Posiluje imunitu',
            'antioxidant': 'Antioxidační účinky',
            'inflammation': 'Protizánětlivé účinky',
            'stomach': 'Napomáhá trávení',
            'bones': 'Zdravé klouby',
            'lungs': 'Podpora dýchacích cest',
          };
        }

        return {
          'immunity': 'Posiluje imunitu',
          'heart': 'Zdravá činnost srdce',
          'workout': 'Přírodní Pre&#8209;Workout',
          'muscle': 'Svalová regenerace',
          'stomach': 'Napomáhá trávení',
          'inflammation': 'Protizánětlivé účinky',
        };
      })()).slice(0, this.smaller ? 4 : 6)),
    };
  },
  mounted() {
    if (this.product.slug.includes("dark")) {
      this.$set(this.items, "gas", "Mírní nadýmání");
      this.$set(this.items, "acne", "Léčí akné");
      this.svgFill = "black";
    }
  },
  computed: {
    itemCount() {
      return Object.values(this.items).length;
    },
  },
  methods: {
    offsetClass(index) {
      if ((this.itemCount % 3) === 0)
        return "";

      if (index !== Math.floor(this.itemCount / 3) * 3)
        return "";

      // Last item of incomplete row -> set offset
      if (this.itemCount % 3 === 1) // One item in last row
        return "offset-3 offset-md-4";
      return "offset-md-2";         // Two items in last row
    }
  }
}
</script>

<style scoped>

</style>
<template>
	<div class="customer-bar temporarily-hide-user">	<!-- TODO - remove class later (also from _navbar.sass) -->
		<!--
		<template v-if="true">				// If logged in
			// TODO - popup (?) with account edit
			<router-link class="account font-color" :to="{ name: 'Account' }">
				<Icon :src="'user' + userIconSuffix"/>
				<span class="nickname">karel33</span>
			</router-link>
			<router-link class="logout" :to="{ name: 'Account' }">odhlásit</router-link>
		</template>

		// TODO - popup (?) with login/registration/forgotten password
		<router-link v-else class="account font-color" :to="{ name: 'Account' }">
			<Icon :src="'user' + userIconSuffix" class="font-color"/>
			<span class="login">přihlášení</span>
		</router-link>
		-->

		<!-- Source: https://www.svgrepo.com/svg/30403/truck -->
		<!--<Icon src="truck" class="change-shipping" @click.native="openDeliveryModal"/>-->

		<router-link class="cart-icon" :to="{ name: 'Cart' }" @mouseover.native="cartHover" @mouseenter.native="cartHover">
			<Icon src="cart"/>
			<span v-if="$api.cart" class="cart-items">{{ $api.cart.count }}</span>
		</router-link>
	</div>
</template>

<script>
	import Icon from "../Icon";
	import {EventBus} from "../../../event-bus";

  export default {
		name: "CustomerBar",
		props: {
			"userIconSuffix": {
				default: ""
			}
		},
		components: { Icon},
		methods: {
			openDeliveryModal() {
				EventBus.$emit("toggleDeliveryModal", true);
			},
      cartHover() {
        EventBus.$emit("miniCartHover");
      },
		},
  }
</script>
<template>
	<span class="loader">
		<span></span>
		<span></span>
		<span></span>
		<span></span>
	</span>
</template>

<script>
	export default {
		name: "Loader"
	}
</script>
import Vue from "vue";

export default [
  // Homepage
  {
    path: "/",
    name: "Home",
    meta: {
      home: true
    },
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/pages/Home")
  },
  {
    path: "/produkty/ginger-shoty",
    name: "ProductsShots",
    meta: {
      home: true
    },
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/pages/Home")
  },
  {
    path: "/produkty/ginger-shoty/detail",
    name: "ProductsShotsOpen",
    meta: {
      home: true
    },
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/pages/Home")
  },
  {
    path: "/produkty/sirupy",
    name: "ProductsSyrups",
    meta: {
      home: true
    },
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/pages/Home")
  },
  {
    path: "/produkty/sirupy/detail",
    name: "ProductsSyrupsOpen",
    meta: {
      home: true
    },
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/pages/Home")
  },
  {
    path: "/proc-uzivat",
    name: "WhyLinear",
    meta: {
      home: true
    },
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/pages/Home")
  },
  {
    path: "/jak-uzivat",
    name: "HowToUse",
    meta: {
      home: true
    },
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/pages/Home")
  },
  {
    path: "/vouchery",
    name: "Vouchers",
    meta: {
      home: true
    },
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/pages/Home")
  },
  {
    path: "/nas-pribeh",
    name: "Story",
    meta: {
      home: true
    },
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/pages/Home")
  },
  {
    path: "/namichej-si",
    name: "Recipes",
    meta: {
      home: true
    },
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/pages/Home")
  },
  {
    path: "/napsali-o-nas",
    name: "Media",
    meta: {
      home: true
    },
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/pages/Home")
  },
  {
    path: "/kde-nas-koupite",
    name: "WhereToBuy",
    meta: {
      home: true
    },
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/pages/Home")
  },
  {
    path: "/kontakt",
    name: "Contact",
    meta: {
      home: true
    },
    component: () =>
      import(/* webpackChunkName: "homepage" */ "../views/pages/Home")
  },
  /*{
    path: "/aktuality",
    name: "News",
    meta: {
      home: true
    },
    component: () =>*/
  //	import(/* webpackChunkName: "homepage" */ "../views/pages/Home")
  /*},
  {
    path: "/suroviny",
    name: "Ingredients",
    meta: {
      home: true
    },
    component: () =>*/
  //import(/* webpackChunkName: "homepage" */ "../views/pages/Home")
  //},


  // User account
  /*{
    path: "/ucet",
    name: "Account",
    component: Account
  },*/

  // Cart
  {
    path: "/kosik",
    name: "Cart",
    meta: {
      isLazy: true
    },
    component: () =>
      import(/* webpackChunkName: "cart" */ "../views/pages/Cart")
  },
  {
    path: "/kosik/udaje",
    name: "Checkout",
    meta: {
      isLazy: true
    },
    component: () =>
      import(/* webpackChunkName: "cart" */ "../views/pages/Checkout"),
    beforeEnter: (to, from, next) => {
      let storage = Vue.prototype.$storage;
      let cart = Vue.prototype.$api.cart;

      if (
        // Shipping not set
        (storage.cart.shipping.required &&
          !storage.cart.shipping.type
        ) ||
        // Payment not set
        (storage.cart.payment.required &&
          !storage.cart.payment.type
        ) ||
        // Cart is empty
        (cart &&
          !cart.count
        )
      )
        // Redirect to Cart instead
        next({
          name: "Cart"
        });
      else
        next();
    }
  },

  // Order - after submitting
  {
    path: "/objednavka/dekujeme",
    name: "OrderDone",
    meta: {
      isLazy: true
    },
    component: () =>
      import(/* webpackChunkName: "order-done" */ "../views/pages/Order/OrderDone")
  },
  {
    path: "/objednavka/bankovni-ucet",
    name: "OrderBankPayment",
    meta: {
      isLazy: true
    },
    component: () =>
      import(/* webpackChunkName: "order-bank" */ "../views/pages/Order/OrderBankPayment")
  },
  {
    path: "/objednavka/online-platba",
    name: "OrderOnlinePayment",
    meta: {
      isLazy: true
    },
    component: () =>
      import(/* webpackChunkName: "order-online" */ "../views/pages/Order/OrderOnlinePayment")
  },
  {
    path: "/objednavka/chyba-brany",
    name: "OrderOnlineCreationError",
    component: () =>
      import(/* webpackChunkName: "order-online-creation-error" */ "../views/pages/Order/OrderOnlineCreationError")
  },

  // Order - return URLs from online payment
  {
    path: "/objednavka/zaplaceno",
    name: "OrderOnlinePaid",
    component: () =>
      import(/* webpackChunkName: "order-online-paid" */ "../views/pages/Order/OrderOnlinePaid")
  },
  {
    path: "/objednavka/chyba-platby",
    name: "OrderOnlineError",
    component: () =>
      import(/* webpackChunkName: "order-online-error" */ "../views/pages/Order/OrderOnlineError")
  },
  {
    path: "/objednavka/ocekavame-platbu/:url",
    name: "OrderOnlinePending",
    component: () =>
      import(/* webpackChunkName: "order-online-pending" */ "../views/pages/Order/OrderOnlinePending")
  },

  // Text pages
  {
    path: "/(obchodni-podminky|gdpr|reklamacni-rad|doprava|cookies)",
    name: "TextPage",
    component: () =>
      import(/* webpackChunkName: "text-page" */ "../views/pages/TextPage")
  },

  // Products
  {
    path: "/produkt/:slug",
    name: "Product",
    meta: {
      isLazy: true
    },
    component: () =>
      import(/* webpackChunkName: "product-detail" */ "../views/pages/Product")
  },

  // Backend iframe pages
  {
    path: "/recenze/:token",
    name: "Review",
    meta: {
      isLazy: true
    },
    component: () =>
      import(/* webpackChunkName: "product-review" */ "../views/pages/BackendIframe")
  },
  {
    path: "/predplatne/:id/:secret/:action",
    name: "Subscription",
    meta: {
      isLazy: true
    },
    component: () =>
      import(/* webpackChunkName: "product-review" */ "../views/pages/BackendIframe")
  },
  {
    path: "/vraceni-sklenic",
    name: "GlassReturn",
    meta: {
      isLazy: true
    },
    component: () =>
      import(/* webpackChunkName: "product-review" */ "../views/pages/BackendIframe.vue")
  },

  //General
  {
    path: "/admin",
    beforeEnter: () => {
      window.location = "https://admin.koju.cz";
    }
  },
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "error-page" */ "../views/pages/errors/Error404")
  }
];
<template>
  <div class="recipe-photo" :class="{ larger, overlay }">
    <img :src="recipe.photos[0]">
    <span class="adult-tag" v-if="recipe.adult">18+</span>
  </div>
</template>

<script>
export default {
  name: "RecipePhoto",
  props: {
    recipe: {
      type: Object,
      required: true,
    },
    larger: {
      type: Boolean,
      required: false,
      default: false,
    },
    overlay: {
      type: Boolean,
      required: false,
      default: false,
    },
  }
}
</script>

<style scoped>

</style>
const getCookie = (name) => {
  const re = new RegExp(name + "=([^;]+)");
  const value = re.exec(document.cookie);
  return (value != null) ? unescape(value[1]) : 'no';
};

// Source: https://stackoverflow.com/a/14344716
const checkCookie = (() => {
  let lastCookie = null;
  return () => {
    const currentCookie = document.cookie;

    // No change
    if (lastCookie !== null && currentCookie === lastCookie) {
      return;
    }

    // No consent given
    if (getCookie('cky-consent') !== 'yes') {
      lastCookie = currentCookie;
      return;
    }

    if (!window.cookieyesCallbacks) {
      return;
    }

    // Loop cookie categories and their callbacks
    for (const [category, callbacks] of Object.entries(window.cookieyesCallbacks)) {
      // Consent for category not given
      if (getCookie(`cookieyes-${category}`) !== 'yes') {
        continue;
      }

      callbacks.forEach(f => f());

      // Empty callbacks
      window.cookieyesCallbacks[category] = [];
    }

    lastCookie = currentCookie;
  };
})();

// Periodically check for cookie change to trigger tracking scripts once they are enabled
const checkIntervalSeconds = 10;

window.setInterval(checkCookie, checkIntervalSeconds * 1000);
checkCookie();
<template>
	<div class="row other-products product-variants" :class="`product-variants-${products.length}`" v-if="products">
		<div v-for="[size, product, isSizeVariant] in products" :key="product.slug + size" class="col-md-6 product" :class="{
      'has-free-shipping': product.free_shipping,
      'sold-out': product.sold_out,
      'col-lg-4': products.length < 4,
    }">
			<span class="image-link">
				<img :src="preview(product, size)" :alt="product.name" loading="lazy" :class="{ wide: !product.special_pack && product.name.includes('+'), syrup: product.is_syrup }">
        <div v-if="product.special_pack" class="promotion-badge">
          <span>
            AKCE!
          </span>
        </div>
        <div v-else-if="product.free_shipping" class="free-shipping-badge">
          <span>
            <Icon src="truck" />
          </span>
          <div>
            Doprava zdarma
          </div>
        </div>
			</span>
			<h3 class="italic font-brown">
        {{ title(product, size, isSizeVariant) }}
			</h3>
      <h4 class="italic font-brown" v-if="subtitle(product, size)">
        {{ subtitle(product, size) }}
      </h4>

      <Price :product="product" :key="product.slug + size" :color="color" @changeSize="() => {}" :default-size="size" :pick-size="false" :allow-buy="!product.sold_out" />

      <div class="promotion-message" v-if="product.special_pack">
        * Akce platí do středy 28. srpna 23.00
      </div>
		</div>
	</div>
</template>

<script>
  import Price from "../pages/Product/Price";
  import Icon from "./Icon";

	export default {
		name: "ProductVariants",
		props: {
		  product: {
        type: Object,
        required: true,
      },
      color: {
        type: String,
        required: false,
        default: "black",
      }
    },
		components: {Icon, Price},
		computed: {
			products() {
        let data = [];

        if (this.product.price_raw !== 0) {
          data.push([
            "smaller",
            this.product,
            true,
          ]);
        }

        data = [
            ...data,
            ...this.product.variants.filter((variant) => variant.price_raw !== 0).map((variant) => ["smaller", variant, false]),
            ...this.product.variants.filter((variant) => variant.price_larger_raw !== 0).map((variant) => ["larger", variant, false]),
        ];

        if (this.product.price_larger_raw !== 0) {
          data.push([
            "larger",
            this.product,
              true
          ]);
        }

				return data;
			},
		},
    methods: {
      // also change in _laravel/app/XmlFeed.php getProductImage()
      preview(product, size) {
        if (product.photos.length === 1)
          return product.photos[0];

        if (product.photos.length < 3 || size === "smaller")
          return product.photos[1];

        return product.photos[2];
      },
      // also change in _laravel/app/XmlFeed.php getProductName() and possibly _laravel/app/Models/Product.php getCartName()
      title(product, size, isSizeVariant) {
        if (product.is_syrup) {
          // e.g., only "Jahoda"
          if (!product.name.includes(" ")) {
            return `Sirup ${product.name.toLowerCase()}`;
          }

          // e.g., "Jahoda 5+1"
          return `Sirupy ${product.name.toLowerCase()}`;
        }

        if (!isSizeVariant) {
          return product.name;
        }

        let volume = size === "smaller" ? product.size : product.size_larger;

        return `${product.name} ${volume}`;
      },
      subtitle(product, size) {
        if (!this.product.is_syrup) {
          return null;
        }

        return size === "smaller" ? product.size : product.size_larger;
      },
    },
	}
</script>
export default {
  addToCart(product, type = "product", quantity = 1) {
    if (!this.isActive())
      return;

    this.pixel("track", "AddToCart", this.getProductData(product, type, quantity));
  },

  purchase(value) {
    if (!this.isActive())
      return;

    this.pixel("track", "Purchase", {
      value: value,
      currency: "CZK"
    });
  },

  viewProduct(product, type = "product", quantity = 1) {
    if (!this.isActive())
      return;

    this.pixel("track", "ViewContent", this.getProductData(product, type, quantity));
  },

  getProductData: (product, type = "product", quantity = 1) => ({
    content_name: product.name,
    content_category: type,
    content_ids: [product.id.toString()],
    content_type: "product",
    value: (product.value_raw ?? product.price_raw) * quantity,
    currency: "CZK"
  }),

  pixel: window.fbq,
  isActive() {
    this.pixel = window.fbq;
    return typeof this.pixel === "function"
  },
}
<template>
	<div class="product-price">
    <div v-if="product.implicit_discount && product.implicit_discount[size]" class="discount-badge">
          <span>
            -{{ product.implicit_discount[size] }} %
          </span>
    </div>
		<strong class="price">
      {{ price }}
      <span v-if="priceDetails" v-html="priceDetails" class="price-details font-color"></span>
    </strong>
    <strong class="price price-sub" :class="{ empty: !priceSubRaw || product.forbid_subscription }" v-if="$api.cart.subscription.active">
      <span>
        <span class="side-text font-color">až</span>
        {{ priceSub }}
      </span>
      <span class="side-text font-color">
        v rámci předplatného

        <HelperTooltip color="white">
          Po aktivaci předplatného Vám každý měsíc automaticky strhneme platbu a odešleme objednávku.
          Zrušit můžete kdykoliv.
          Více informací naleznete v košíku.
        </HelperTooltip>
      </span>
    </strong>
		<ul class="size-picker" :class="size" v-if="allowLargeSize && pickSize">
			<li @click="changeSize" :class="{ active: size === 'smaller' }">{{ product.size }}</li>
			<li @click="changeSize(false)" :class="{ active: size === 'larger' }">{{ product.size_larger }}</li>
		</ul>
    <b class="bottles-in-package" v-if="product.bottles_in_package > 1 && pickSize">
      {{ product.bottles_in_package }} x {{ size === 'smaller' ? product.size : product.size_larger }}
    </b>
    <strong class="font-brown tick-before" v-if="product.name === 'Starter pack'">
      Doprava zdarma
    </strong>
    <BuyButton :class="!allowBuy ? 'hidden' : ''" :size="size" :color="color" :product="product" :quantity="quantity" @changeQuantity="changeQuantity" />
	</div>
</template>

<script>
	import BuyButton from "../../components/BuyButton";
  import HelperTooltip from '@/views/components/HelperTooltip.vue';
  export default {
		name: "Price",
    components: { HelperTooltip, BuyButton},
    props: {
      product: {
        type: Object,
        required: true,
      },
      allowBuy: {
        type: Boolean,
        required: false,
        default: true,
      },
      pickSize: {
        type: Boolean,
        default: true,
      },
      defaultSize: {
        type: String,
        default: "smaller",
      },
      color: {
        type: String,
        required: false,
        default: "black",
      },
    },
		data() {
			return {
				size: this.defaultSize,
				allowLargeSize: this.product.price_larger_raw !== 0,
				quantity: 1,
			};
		},
		computed: {
			priceRaw() {
				return this.size === "smaller" ? this.product.price_raw : this.product.price_larger_raw;
			},
			price() {
				return this.$price(this.priceRaw * this.quantity);
			},
      priceSubRaw() {
        return Math.min(...Object.values(this.size === "smaller" ? this.product.price_subscription_raw : this.product.price_larger_subscription_raw));
      },
      priceSub() {
        return this.$price(this.priceSubRaw);
      },
      priceDetails() {
			  return this.size === "smaller" ? this.product.price_details : this.product.price_details_larger;
      }
		},
		methods: {
			changeSize(smaller = true) {
				this.size = smaller ? "smaller" : "larger";
				this.quantity = 1;
				this.$emit("changeSize", this.size);
			},
      changeQuantity(quantity) {
			  this.quantity = quantity;
      }
		}
	}
</script>

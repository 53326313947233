<template>
	<div class="modal-backdrop" :class="{ open: open }" @click.self="backdropClick">
		<div class="modal">
			<span class="close-modal" @click="close">✕</span>
			<div class="modal-body row">
				<div class="col-12" v-if="$slots.title">
					<h3>
						<slot name="title"/>
					</h3>
				</div>
				<slot name="body"/>
			</div>
			<div class="modal-loader" v-if="loading">
				<Loader/>
			</div>
		</div>
	</div>
</template>

<script>
	import Loader from "../Loader";
	import {EventBus} from "../../event-bus";

	export default {
		name: "Modal",
		components: {Loader},
		props: {
			"open": {
				type: Boolean
			},
			"loading": {
				type: Boolean
			},
			"closeOnBackdropClick": {
				type: Boolean,
				default: true
			}
		},
		methods: {
			close() {
				this.$emit("close");
			},
			backdropClick() {
				if (this.closeOnBackdropClick)
					this.close();
			}
		},
		watch: {
			open(val) {
				EventBus.$emit("toggleBodyScroll", !val);
			}
		}
	}
</script>
<template>
	<nav class="row">
		<div class="col-lg-2 col-xl-1 logo">
			<Icon src="menu" class="d-lg-none hamburger-toggle" @click.native="toggleNavbar"/>
			<Icon src="close" class="d-lg-none hamburger-toggle" @click.native="toggleNavbar"/>
			<router-link :to="{ name: 'Home' }">
				<Icon src="logo"/>
			</router-link>
		</div>
		<div class="col-lg-6 col-xl-8 nav-links">
			<ul class="font-color">
        <li>
          <router-link :class="{active: $route.name && $route.name.includes('Product')}" :to="{ name: 'Home' }" active-class="null" exact>Produkty</router-link>
          <ul class="nav-submenu bg-theme-light">
            <li>
              <router-link :class="{active: productsPageActiveShot}" :to="{ name: 'ProductsShotsOpen' }">Ginger shoty</router-link>
            </li>
            <li>
              <router-link :class="{active: productsPageActiveSyrup}" :to="{ name: 'ProductsSyrupsOpen' }">Sirupy</router-link>
            </li>
          </ul>
        </li>
        <!--
				<li>
					<router-link :to="{ name: 'WhyLinear' }">Proč užívat</router-link>
				</li>
        <li>
          <router-link :to="{ name: 'HowToUse' }">Jak užívat</router-link>
        </li>
        -->
        <li>
          <router-link :to="{ name: 'Vouchers' }">Vouchery</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Story' }">Náš příběh</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Media' }">Napsali o nás</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'WhereToBuy' }">Kde nás najdete</router-link>
        </li>
        <!--
        <li>
          <router-link :to="{ name: 'Recipes' }">Namíchej si</router-link>
        </li>
        -->
				<li>
					<router-link :to="{ name: 'Contact' }">Kontakt</router-link>
				</li>
        <li>
          <router-link :to="{ name: 'GlassReturn' }">Vrácení sklenic</router-link>
        </li>
			</ul>
		</div>
		<div class="col-lg-4 col-xl-3 social">
			<CustomerBar user-icon-suffix="2"/>
			<a href="https://www.facebook.com/KOJUcz" target="_blank" rel="noopener">
				<Icon src="fb"/>
			</a>
			<a href="https://www.instagram.com/koju_cz" target="_blank" rel="noopener">
				<Icon src="ig"/>
			</a>
      <a href="https://www.tiktok.com/@koju_cz1" target="_blank" rel="noopener">
        <Icon src="tiktok"/>
      </a>
		</div>
	</nav>
</template>

<script>
	import Icon from "../Icon";
	import CustomerBar from "./CustomerBar";

	export default {
		name: "Navbar",
		components: {
			CustomerBar,
			Icon
		},
		methods: {
			toggleNavbar() {
				this.$emit("toggle-navbar");
			}
		},
		computed: {
			// Custom class assignment due for product detail pages
			productsPageActive() {
				return this.$route.name === "Product";
			},
      productsPageActiveShot() {
        return this.productsPageActive && (this.$api.products[this.$route.params.slug]?.is_syrup ?? false) === false;
      },
      productsPageActiveSyrup() {
        return this.productsPageActive && !this.productsPageActiveShot;
      },
		}
	}
</script>

const getRecipeObject = (recipe) => {
  const split = recipe.raw.split("<hr>");
  const adult = split.length >= 3;
  const videoIndex = adult ? 1 : 0;
  const videoId = split[videoIndex].match(/watch\?v=([^"<]+)/)[1];
  const text = split[videoIndex + 1];

  return {
    name: recipe.name,
    photos: recipe.photos,
    adult,
    videoId,
    text
  };
};

export const getAllRecipies = (apiData) => Object.entries(apiData.texts)
  .filter(([label]) => label.substr(0, "recipe".length) === "recipe")
  .map(([, text]) => getRecipeObject(text));
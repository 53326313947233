<template>
	<button :class="'color-' + color + (rtl ? ' rtl' : '')" :disabled="loading">
		<slot/>
		<Loader v-if="loading"/>
	</button>
</template>

<script>
	import Loader from "../Loader";
	export default {
		name: "Button",
		components: {Loader},
		props: [
			"color",
			"rtl",
			"loading"
		]
	}
</script>

<template>
  <div class="product-review">
    <div class="review-header">
      <RatingStars :rating="review.rating"/>
      <h6 class="review-name" v-if="review.name">{{ review.name }}</h6>
      <small class="review-date">{{ review.date }}</small>
    </div>
    <!--<h5 class="review-heading" v-if="review.title">{{ review.title }}</h5>-->
    <p class="review-text" v-if="review.text">
      {{ review.text }}
    </p>
  </div>
</template>

<script>
import RatingStars from "@/views/pages/Product/RatingStars";

export default {
  name: "ProductReview",
  components: {RatingStars},
  props: [
    "review"
  ]
}
</script>

<style scoped>

</style>
<template>
  <div :class="`recipes-sidebar row${ smaller ? ' recipes-sidebar-smaller' : '' }`">
    <div
        :class="smaller ? 'col-6 col-sm-3 col-md-6 col-lg-3' : 'col-6 col-sm-4 col-lg-6'"
        v-for="recipe in recipes.slice(0, smaller ? 4 : 6)"
        :key="recipe.id"
    >
      <RecipePhoto :recipe="recipe" />
    </div>
    <div class="col-12 recipes-sidebar-paragraph">
      <p>
        Připravili jsme pro vás ukázky, jak si snadno připravit drinky s naší Woody sodou a Ginger
        shotem.
      </p>
    </div>
    <div class="col-12">
      <router-link :to="{ name: 'Recipes' }" class="no-hover-underline">
        <Button :color="smaller ? 'black' : 'white'">
          Chci recept
        </Button>
      </router-link>
      <Button v-if="!smaller" color="black" @click.native="$emit('scrollToBuy')" class="buy-scroll-button">Koupit</Button>
    </div>
  </div>
</template>

<script>
import {getAllRecipies} from "../../../selectors";
import RecipePhoto from "../../components/RecipePhoto";
import Button from "../../components/Button";

export default {
  name: "RecipesSidebar",
  components: {Button, RecipePhoto},
  props: {
    smaller: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      recipes: getAllRecipies(this.$api)
    };
  },
}
</script>

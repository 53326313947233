<template>
  <div class="container-fluid alert-bar">
    <template v-if="content">
      <Icon :src="content.icon" path="icons/alert-bar" />
      <span v-html="content.html"></span>
    </template>
    <template v-else>
      <Icon src="truck" />
      <span>
        Doprava ZDARMA
        <template v-if="globalFreeShipping">
          na vše
        </template>
        <template v-else>
          od <template v-if="$api.cart">{{ $price($api.cart.freeShippingMin) }}</template>
        </template>
        <span class="additional-text">
          - doručujeme po celé ČR/SK
        </span>
      </span>
    </template>
    <button type="button" @click="hide()">✕</button>
  </div>
</template>

<script>
import Icon from "../Icon";
import LocalStorage from '@/local-storage';
export default {
  name: "AlertBar",
  components: {Icon},
  computed: {
    globalFreeShipping() {
      const freeShippingUntil = new Date(2024, 7 - 1, 14, 20);

      return (this.$api.cart && this.$api.cart.freeShippingMin <= 0) ||
          new Date() < freeShippingUntil;
    },
    content() {
      return this.$api._data.alertBar
    },
    localStorageKey() {
      if (!this.content) {
        return "alert-bar-free-shipping";
      }

      return "alert-bar-" + btoa(encodeURI(this.content.text)).substring(0, 32);
    },
  },
  methods: {
    hide() {
      LocalStorage.set(this.localStorageKey, {}, {
        days: 3
      });
      this.$emit('hide');
    },
  },
  created() {
    this.$nextTick(() => {
      if (LocalStorage.get(this.localStorageKey)) {
        this.$emit('hide');
      }
    });
  },
}
</script>
import Vue from "vue";
import VueRouter from "vue-router";
import {EventBus} from "../event-bus";
import routes from "./routes";

// Google Analytics
const gaId = process.env.VUE_APP_GOOGLE_ANALYTICS_ID;

window.dataLayer = window.dataLayer || [];
window.gtag = function () {
  window.dataLayer.push(arguments);
}

if (gaId) {
  window.gtag("js", new Date());
  window.gtag("config", gaId);
}

// Router
Vue.use(VueRouter);

const router = new VueRouter({
  base: process.env.VUE_APP_BASE_ROUTE,
  mode: "history",
  routes
});

router.beforeEach((newRoute, oldRoute, next) => {
  if (gaId) {
    window.gtag("set", "page", newRoute.path);
  }

  // Going from other page to lazy loaded page -> show loading
  if (oldRoute.name && newRoute.meta.isLazy)
    EventBus.$emit("loading", true);

  next();
});

router.afterEach((newRoute, oldRoute) => {
  // Going from other page to lazy loaded page -> hide loading
  if (oldRoute.name && newRoute.meta.isLazy)
    EventBus.$emit("loading", false);
});

export default router;

<template>
  <Modal :open="open" @close="open = false" :close-on-backdrop-click="true" class="promoted-product-modal modal-image"> <!-- .modal-wide also available instead of .modal-image -->
    <template v-slot:body v-if="popup">
      <!-- No link -->
      <img v-if="!link.type" :src="popup.image" alt="Popup" @click="open = false">

      <!-- Product link -->
      <router-link v-else-if="link.type === 'product'" :to="{ name: 'Product', params: { slug: link.value } }" @click.native="open = false">
        <img :src="popup.image" alt="Popup">
      </router-link>

      <!-- Native link -->
      <a v-else :href="link.value" @click="open = false">
        <img :src="popup.image" alt="Popup">
      </a>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";
import LocalStorage from "../../local-storage";

export default {
  name: "PromotedProductModal",
  components: {Modal},
  props: {
    productSlug: String
  },
  data() {
    return {
      popup: null,
      localStorageKey: null,
      open: false,
      link: {
        type: null,
        value: null,
      }
    };
  },
  watch: {
    open(value) {
      if (!value)
        LocalStorage.set(this.localStorageKey, {}, {
          days: 1
        });
    }
  },
  created() {
    this.popup = this.$api._data.popup;

    if (!this.popup) {
      return;
    }

    this.localStorageKey = "hideModal" + this.popup.image;

    if (this.popup.link) {
      if (this.popup.link.substring(0, 2) === "p:") {
        this.link = {
          type: "product",
          value: this.popup.link.substring(2),
        };
      } else {
        this.link = {
          type: "native",
          value: this.popup.link,
        };
      }
    }

    this.$nextTick(() => {
      this.open = this.$route.name !== "Product" && !LocalStorage.get(this.localStorageKey);
    });
  }
}
</script>

<template>
	<div v-if="Object.keys(messages).length" class="flash-messages">
		<div v-for="(data, id) in messages" :key="id" :class="'flash flash-' + data.type">
			<span class="content">{{ data.text }}</span>
			<span class="close" @click="$emit('close', id)">✕</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: "FlashMessages",
		props: ["messages"]
	}
</script>
import api from "./api";

export default {
  id: process.env.VUE_APP_GOOGLE_ADS_SEND_TO,

  conversion(value) {
    if (this.id && typeof window.gtag === "function") {
      window.gtag('event', 'conversion', {
        'send_to': this.id,
        'value': value,
        'currency': 'CZK',
        'transaction_id': '',
      });
    }
  },

  _dataLayer() {
    return window.dataLayer;
  },

  _clearDataLayer() {
    this._dataLayer().push({ecommerce: null});  // Clear the previous ecommerce object.
  },


  _cartContentToProductsObject(cartContent) {
    return Object.values(cartContent).map((cartItem) => ({
      id: cartItem.id,
      name: cartItem.name,
      price: cartItem.price,
      brand: 'Koju',
      category: cartItem.options.type,
      variant: cartItem.options.size ?? cartItem.options.shipping, // size for products / shipping type for vouchers
      quantity: cartItem.qty,
      // coupon: ''
    }));
  },

  purchase(data) {
    this._clearDataLayer();
    this._dataLayer().push({
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': data.code,
            'revenue': data.total,
            'shipping': data.oldCart.shipping_price,
            // 'tax': 4.99,
            'coupon': data.oldCart.discountObject ? data.oldCart.discountObject.code : null,
          },
          'products': this._cartContentToProductsObject(data.oldCart.content)
        }
      }
    });
  },

  checkout(step) {
    this._clearDataLayer();
    this._dataLayer().push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {step},
          'products': this._cartContentToProductsObject(api.cart.content)
        }
      },
    });
  },

  checkoutOption(optionType, optionValue, step = 1) {
    this._clearDataLayer();
    this._dataLayer().push({
      'event': 'checkoutOption',
      'ecommerce': {
        'checkout_option': {
          'actionField': {'step': step, 'option': `${optionType}-${optionValue}`}
        }
      }
    });
  },
}
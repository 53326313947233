<template>
	<div class="error-page">
		<img src="../../../assets/icons/logo.svg" alt="Logo Koju.cz">
		<h1>Na webu se vyskytl problém</h1>
		<p>Omlouváme se, ale web momentálně není dostupný.</p>
		<p>Zkuste <a href="">obnovit stránku</a>. Pokud problém nezmizí, kontaktujte nás prosím na info@koju.cz</p>
	</div>
</template>

<script>
	export default {
		name: "Error503"
	}
</script>
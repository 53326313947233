<template>
  <div class="row effects-wrapper hacker-effects-wrapper">
    <div class="col-6 col-xl-4 effect" :class="offsetClass(index)" v-for="(item, index) of items" :key="item">
      <Icon :src="item" path="images/product-icons-hacker" />
      <h4 v-html="$api.texts[`hacker_effects_${item}`].name"></h4>
      <p v-html="$api.texts[`hacker_effects_${item}`].stripped"></p>
    </div>
  </div>
</template>

<script>
import Icon from "@/views/components/Icon";

export default {
  name: "EffectsIconsHacker",
  props: [
    "product"
  ],
  components: {
    Icon
  },
  data() {
    return {
      items: [
        "fatigue", "nerves", "sight", "bones", "inflammation", "stomach",
      ],
    };
  },
  mounted() {
    if (this.product.slug.includes("dark")) {
      this.$set(this.items, "gas", "Mírní nadýmání");
      this.$set(this.items, "acne", "Léčí akné");
      this.svgFill = "black";
    }
  },
  computed: {
    itemCount() {
      return Object.values(this.items).length;
    }
  },
  methods: {
    offsetClass(index) {
      if (!this.itemCount % 3)
        return "";

      if (index !== Math.floor(this.itemCount / 3) * 3)
        return "";

      // Last item of incomplete row -> set offset
      if (this.itemCount % 3 === 1) // One item in last row
        return "offset-3 offset-md-4";
      return "offset-md-2";         // Two items in last row
    }
  }
}
</script>

<style scoped>

</style>